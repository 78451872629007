import React, {useState} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import { detailContainer, detailContent, detailSubSections, kebabMenu, titleDetail } from '../animations/detail'
import {ReactComponent as TriangleSVG} from '../../svg/triangle.svg'
import Textarea from 'react-textarea-autosize';
import {ReactComponent as KebabMenuSVG} from '../../svg/kebabMenu__icon.svg'
import {ReactComponent as AddSVG} from '../../svg/add_icon.svg'
import AddImage from './components/AddImage';
import {ReactComponent as CancelSVG} from '../../svg/cancel__icon.svg'
import {v4} from 'uuid'
import Save from './components/Save';
import { hostname } from '../../config/server';
import {ReactComponent as YoutubeSVG} from '../../svg/youtube__logo.svg'
import Visualizer from './components/Visualizer';
import ImagePreview from './components/ImagePreview';



export default function Detail(props) {
    const [popupSave, setPopupSave] = useState(false)
    const [visualizer, setVisualizer] = useState("")
    const [image, setImage] = useState({
        status : false, 
        subSection : null, 

    })
    const [imagePreview, setImagePreview] = useState("")


    const handleReturn = () => {
        if( JSON.stringify(props.dataTutorials.find(element => element.id === props.detailTutorial.id)) === JSON.stringify(props.detailTutorial)) {
            props.setDetailTutorial({})
        } else {
            setPopupSave(true)
        }

        props.setListTutorials(props.dataTutorials)
    }

    const handleEdit = (e) => {
        props.setDetailTutorial(state => {
            return {
                ...state, 
                [e.target.name] : e.target.value
            }
        })
    }

    const handleEditSubSection = (e, key) => {
        props.setDetailTutorial(state => {
            return {
                ...state, 
                subSections : state.subSections.map((element) => element.id === key ? {                             ...element, 
                    [e.target.name] : e.target.value} : element)
            }

        })
    }

    const handleAdd = () => {
        const newSection = {
            status : {
                menu : false, 
                image : false
            }, 
            id : v4(), 
            title : "", 
            content : "", 
            image : []
        }

        props.setDetailTutorial(state => {
            return {
                ...state, 
                subSections : [
                    ...state.subSections, 
                    newSection
                ]
            }
        })
    }

    const handlesetController = (key, type) => {
        props.setDetailTutorial(state => {
            return {
                ...state, 
                subSections : state.subSections.map((element) => element.id === key ? {                             
                    ...element, 
                    status : {
                        ...element.status, 
                        [type] : !element.status[type]
                    }
                
                } : element)
            }
        })

        if(type === "image") {
            props.setDetailTutorial(state => {
                return {
                    ...state, 
                    subSections : state.subSections.map((element) => element.id === key ? {                             
                        ...element, 
                        status : {
                            ...element.status, 
                            menu : false
                        }
                    
                    } : element)
                }
            })
        }
    }

    const handleImage = (id) => {
        setImage({
            status : true, 
            subSection : id
        })

        props.setDetailTutorial(state => {
            return {
                ...state, 
                subSections : state.subSections.map((element) => element.id === id ? {                             
                    ...element, 
                    status : {
                        ...element.status, 
                        menu : false
                    }
                } : element)
            }
        })
    }

    const handleDeleteSubImage = (idSubSection, idSubImage) => {
        props.setDetailTutorial(state => {
            return {
                ...state, 
                subSections : state.subSections.map(element => {
                    if(element.id === idSubSection) {
                        return {
                            ...element, 
                            image : element.image.filter((subElement, position) => position !== idSubImage)
                        }
                    } else {
                        return element
                    }
                })
            }
        })
    }

    const handleDeleteSubSection = (idSubSection) => {
        props.setDetailTutorial(state => {
            return {
                ...state, 
                subSections : state.subSections.filter(element => element.id !== idSubSection)
            }
        })

    }


    return (
        <React.Fragment>
        {
            imagePreview.length > 0 ? 
                <ImagePreview 
                    imagePreview = {imagePreview}
                    setImagePreview = {setImagePreview}
                />
            : 
                null
        }

        <AnimatePresence exitBeforeEnter initial = {false}>
            {
                visualizer.length > 0 ? 
                    <Visualizer 
                        setVisualizer={setVisualizer}
                        visualizer = {visualizer}
                        detailTutorial = {props.detailTutorial}
                        handleEdit = {handleEdit}
                    />
                : 
                    null 
            }
        </AnimatePresence>

        <AnimatePresence exitBeforeEnter initial = {false}>
            {
                popupSave ? 
                    <Save 
                        setPopupSave = {setPopupSave}
                        setDetailTutorial = {props.setDetailTutorial}
                        detailTutorial = {props.detailTutorial}
                        setDataTutorials = {props.setDataTutorials}
                        dataTutorials = {props.dataTutorials}
                        setListTutorials = {props.setListTutorials}
                        setLoader = {props.setLoader}
                        setStatusRequest ={props.setStatusRequest}
                    />
                : 
                    null
            }
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter initial = {false}>
        {
            image.status ?
                <AddImage 
                    image = {image}
                    setImage = {setImage}
                    setDetailTutorial = {props.setDetailTutorial}
                    detailTutorial = {props.detailTutorial}
                    setStatusRequest ={props.setStatusRequest}
                />
            : 
                null
        }
        </AnimatePresence>
        
        <motion.div 
        exit = "exit"
        animate = "animate"
        initial = "initial"
        variants = {detailContainer}
        transition = {{
            type : "linear"
        }}
        className = "detailTuto"
        >
            <motion.div 
                className = "detailTuto__return" 
                onClick = {handleReturn}
            >
                <motion.div whileTap = {{scale : 0.8}}>
                    <div><TriangleSVG /></div>
                    <div>Retour</div>
                </motion.div>
            </motion.div>

            <motion.form 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants = {detailContent}
            
                className = "detailTuto__container">
                <div className="detailTuto__container__element">
                    <motion.div 
                            whileInView = "onscreen"
                            initial = "offscreen"
                            viewport = {{once : true, amount : 0}}
                            variants = {titleDetail}
                            className = "detailTuto__container__element__title"
                        >
                            <input type = "text" name = "title" value = {props.detailTutorial.title} onChange = {handleEdit}/>
                            <input type = "text" name = "titleEn" value = {props.detailTutorial.titleEn} onChange = {handleEdit}/>
                    </motion.div>

                    <div className = "detailTuto__container__element__content">

                    {
                            props.detailTutorial.subSections.map((element, position) => (
                                <motion.div 
                                    whileInView = "onscreen"
                                    initial = "offscreen"
                                    viewport = {{once : false, amount : 0}}
                                    variants = {detailSubSections}
                                    key = {element.id}
                                >   
                                    <div className="kebabMenu">
                                        <motion.div whileTap={{scale : 0.8}} onClick = {() => handlesetController(element.id, "menu")}><KebabMenuSVG /></motion.div>
                                        <AnimatePresence exitBeforeEnter initial = {false}>
                                        {
                                            element.status.menu ? 
                                                <React.Fragment>
                                                    <div className='closeKebabMenu' onClick = {() => handlesetController(element.id, "menu")}></div>
                                                <motion.ul
                                                    exit = "exit"
                                                    animate = "animate"
                                                    initial = "initial"
                                                    variants = {kebabMenu}
                                                >
                                                    <motion.li whileTap={{scale : 0.8}} onClick = {() => handleDeleteSubSection(element.id)}>Supprimer le bloc de contenu</motion.li>
                                                    {
                                                        element.image.length > 0 ? 
                                                            <motion.li whileTap={{scale : 0.8}} onClick = {() => handlesetController(element.id, "image")}>{!element.status.image ? "Afficher" : "Masquer"} la suppression des images</motion.li>
                                                        : 
                                                            null
                                                    }

                                                    <motion.li 
                                                        whileTap={{scale : 0.8}}
                                                        onClick = {() => handleImage(element.id)}
                                                    
                                                    >Ajouter une image</motion.li>
                                                </motion.ul>
                                                </React.Fragment>
                                            : 
                                            null
                                        }
                                        </AnimatePresence>
 
                                    </div>
                                    <input  type = "text" value = {element.title} className = "title" name = "title" placeholder = "Titre" onChange = {(e) => handleEditSubSection(e, element.id)} 
                                    />
                                    
                                    
                                    {
                                        element.image ? 
                                            element.image.length > 0 ? 
                                                element.image.map((subElement, position) => (
                                                    !subElement[2] ? 
                                                        <div key = {JSON.stringify(subElement)} 
                                                        className = "subImage" 
                                                        onClick = {() => !element.status.image ? setImagePreview(URL.createObjectURL(subElement[0])) : null}
                                                        >
                                                            {
                                                                element.status.image ? 
                                                                    <div className='subImage__delete' onClick = {() => handleDeleteSubImage(element.id, position)}>
                                                                        <CancelSVG />
                                                                    </div>
                                                                : 
                                                                    null
                                                            }

                                                            <img  src = {URL.createObjectURL(subElement[0])}

                                                            alt = {subElement[1]} />
                                                        </div>

                                                    : 
                                                        <div 
                                                        
                                                            key = {JSON.stringify(subElement)} 
                                                            className = "subImage" 
                                                            onClick = {() => !element.status.image ? setImagePreview(`${hostname}/images/tutorials/${subElement[1]}`) : null}

                                                        >
                                                            {
                                                                element.status.image ? 
                                                                    <div className='subImage__delete'  onClick = {() => handleDeleteSubImage(element.id, position)}>
                                                                        <CancelSVG />
                                                                    </div>
                                                                : 
                                                                    null
                                                            }
                                                            <img  src = {`${hostname}/images/tutorials/${subElement[1]}`}

                                                            alt = {subElement[0]}
                                                            />
                                                        </div>
                                                ))
                                            
                                            : null
                                        : 
                                            null
                                    }

                                    <Textarea value = {element.content} className = "content" name = "content" placeholder = "Tapez un contenu" onChange = {(e) => handleEditSubSection(e, element.id)} />


                                </motion.div>
                            ))
                        }

                        <div id="add">
                            <motion.div  whileTap={{scale : 0.8}} onClick = {handleAdd}><AddSVG /></motion.div>
                            <div>Ajouter du contenu</div>
                        </div>
                        
                        </div>
                </div>

                <div className='detailTuto__container__link'>
                    <motion.div 
                        whileTap={{scale : 0.8}}
                        onClick = {() => setVisualizer(props.detailTutorial.embedId)}
                    >
                        <YoutubeSVG />
                    </motion.div>
                </div>
            </motion.form>

        </motion.div>
        </React.Fragment>
    
    )
}