import React, {useEffect} from 'react'
import lottie from "lottie-web";
import animationData from '../../lottie/logo-AE-MEMO.json'


export default function Header() {
    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#react-logo"),
          animationData: animationData,
          renderer: "svg", // "canvas", "html"
          loop: true, // boolean
          autoplay: true, // boolean
        });
      }, []);
    return (
        <div className = "app__title">
            <div id="react-logo"  />
        </div>
    )
}