import React, {useState} from 'react'
import Header from './base/Header'
import Loader from './base/Loader'
import { AnimatePresence } from 'framer-motion'
import axios from 'axios'
import { hostname } from '../config/server'
import Homepage from './homepage/Homepage'
import Detail from './detail/Detail'
import StatusRequest from './base/StatusRequest'

export default function Main() {
    const [loader, setLoader] = useState(true)
    const [dataTutorials, setDataTutorials] = useState([])
    const [listTutorials, setListTutorials] = useState([])
    const [detailTutorial, setDetailTutorial] = useState({})
    const [dataStatus, setDataStatus] = useState(false)
    const [statusRequest, setStatusRequest] = useState({})

    if(!dataStatus) {
        axios.get(`${hostname}/tutorials/all`)
        .then(res => {
            const data = res.data.map(element => {
                return {
                    ...element, 
                    subSections : JSON.parse(element.subSections)
                }
            })
            setDataTutorials(data)
            setListTutorials(data)
            setLoader(false)
        })
        .catch(err => {
            console.log(err)
        })

        setDataStatus(true)
    }


    return (
        <React.Fragment>
            <Header />

            <AnimatePresence exitBeforeEnter initial = {false}>
                {
                    Object.entries(statusRequest).length > 0 ? 
                        <StatusRequest 
                            statusRequest = {statusRequest}
                            setStatusRequest = {setStatusRequest}
                        />
                    : 
                        null
                }
            </AnimatePresence>

            <AnimatePresence exitBeforeEnter initial = {false}>
                {
                    Object.entries(detailTutorial).length === 0 ? 
                        <Homepage 
                            dataTutorials = {dataTutorials}
                            setDataTutorials = {setDataTutorials}
                            setListTutorials = {setListTutorials}
                            listTutorials = {listTutorials}
                            setListTutorials = {setListTutorials}
                            setDetailTutorial = {setDetailTutorial}
                            setLoader = {setLoader}
                            setStatusRequest = {setStatusRequest}
                        />
                    : 
                        <Detail 
                            dataTutorials = {dataTutorials}
                            setListTutorials = {setListTutorials}
                            setDetailTutorial = {setDetailTutorial}
                            detailTutorial = {detailTutorial}
                            setDataTutorials = {setDataTutorials}
                            setLoader = {setLoader}
                            setStatusRequest = {setStatusRequest}
                        />

                }
            </AnimatePresence>



            <AnimatePresence exitBeforeEnter initial = {false}>
                {
                    loader ? 
                        <Loader />
                    : 
                        null 
                }
            </AnimatePresence>
        </React.Fragment>
    )
}