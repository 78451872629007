export const homepageContainer = {
    exit : {
        x : 1000, 
    }, 
    animate : {
        x : 0, 
    }, 
    initial : {
        x : 1000, 
    }
}

export const listElementHomepage = {
    exit : {
        x : 1000, 
    }, 
    animate : {
        x : 0, 
        transition : {
            delay : 0.3, 
            type : "linear"
        }
    }, 
    initial : {
        x : 1000, 
    }
}

export const noDataHomepage = {
    exit : {
        scale : 0
    }, 
    animate : {
        scale : 1, 
        transition : {
            delay : 0.3
        }
    }, 
    initial : {
        scale : 0
    }
}

export const deleteTutoVariants = {
    exit : {
        scale : 0, 
    }, 
    animate : {
        scale : 1,
    }, 
    initial : {
        scale : 0,
    }
}
