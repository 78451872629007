import React, {useState} from 'react'
import { motion } from 'framer-motion'
import { addImageContainer, addImageContent } from '../../animations/addImage'
import {ReactComponent as CancelSVG} from '../../../svg/cancel__icon.svg'
import {ReactComponent as SaveSVG} from '../../../svg/save__icon.svg'
import axios from 'axios'
import { hostname } from '../../../config/server'

export default function Save(props) {
    const [saveData, setSaveData] = useState(props.detailTutorial)
    const [dataStatus, setDataStatus] = useState(false)
    const [files, setFiles] = useState([])

    if(!dataStatus) {
        const filesTutorial = []
        setSaveData(state => {
            return {
                ...state, 
                subSections : state.subSections.map(element => {
                    return {
                        ...element, 
                        status : {
                            menu : false, 
                            image : false
                        },
                        image : element.image.map(subElement => {
                            if(!subElement[2]) {
                                var file = subElement[0]
                                var blob = file.slice(0, file.size, file.type)
                                const newFile = new File([blob], subElement[1], {type : blob.type})
                                filesTutorial.push(newFile)
                                console.log(blob)
    
                                return [subElement[1], `${subElement[1]}.${blob.type.split('/')[1]}`, true]

                            } else {
                                return subElement
                            }

                        })
                    }
                })
            }
        })

        setFiles(filesTutorial)

        setDataStatus(true)
    }

    const handleSubmit = async () => {
        props.setLoader(true)
        /*
        for(var key in props.detailTutorial) {
            formContainer.append(key, props.detailTutorial[key])
        }





        var file = props.detailTutorial.subSections[0].image[0][0]
        var blob = file.slice(0, file.size, file.type)
        
        const newFile = new File([blob], props.detailTutorial.subSections[0].image[0][1], {type : blob.type} )

        */


        const formContainer = new FormData()
        formContainer.append('content', JSON.stringify(saveData))


        for(var element of files) {
            var file = new FormData()
            file.append('file', element)
            axios.post(`${hostname}/tutorials/upload`, file, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                //console.log(res.data)

            })
            .catch(err => {
                //console.log(err)
            })
        }

        axios.post(`${hostname}/tutorials/edit`, saveData)
        .then(res => {
            //console.log(res.data)
            const filteredData = [
                saveData,
                ...props.dataTutorials.filter(element => element.id !== saveData.id), 
            ]
            
            props.setDataTutorials(filteredData)
            props.setListTutorials(filteredData)
            props.setDetailTutorial({})
            props.setLoader(false)
            props.setStatusRequest({
                status : true, 
                content : "Le mémo est modifié avec succès"
            })
            
        })
        .catch(err => {
            props.setLoader(false)
            return props.setStatusRequest({
                status : false, 
                content : "Une erreur est survenue, veuillez réessayer"
            })
        })
    }


    return (
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            variants = {addImageContainer}
            className="save"
        >
            <motion.div
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants = {addImageContent}
            >
                <div className = "save__header">
                    <div>
                        <motion.div 
                            whileTap = {{scale : 0.8}}
                            onClick = {() => props.setPopupSave(false)}
                        >
                            <CancelSVG />
                        </motion.div>
                    </div>
                </div>
                <div className = "save__content">
                    <SaveSVG />
                    <p>Souhaitez-vous enregistrer les modifications ?</p>
                </div>
                <div className = "save__buttons">
                    <motion.div whileTap = {{scale : 0.8}} onClick = {handleSubmit}>Enregistrer</motion.div>
                    <motion.div whileTap = {{scale : 0.8}} onClick = {() => props.setDetailTutorial({})}>Ne pas enregistrer</motion.div>

                </div>
            </motion.div>
        </motion.div>
    )
}