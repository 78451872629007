

export const loaderContainer = {
    exit : {
        opacity : 0
    }, 
    animate : {
        opacity : 1
    }, 
    initial : {
        opacity : 0
    }
}

export const loaderContent = {
    exit : {
        scale : 0
    }, 
    animate : {
        scale : 1, 
        transition : {
            delay : 0.3
        }
    }, 
    initial : {
        scale : 0
    }
}