import React, {useState} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { deleteTutoVariants, homepageContainer, listElementHomepage, noDataHomepage } from '../animations/homepage'
import { ReactComponent as TriangleSVG} from '../../svg/triangle.svg'
import { ReactComponent as NoDataSVG} from '../../svg/dbNoData__icon.svg'
import {ReactComponent as AddSVG} from '../../svg/add_icon.svg'
import {ReactComponent as CancelSVG} from '../../svg/cancel__icon.svg'
import Add from './components/Add'
import axios from 'axios'
import { hostname } from '../../config/server'
import {ReactComponent as MinusSVG} from '../../svg/minus__icon.svg'


export default function Homepage(props) {
    const [deleteTuto, setDeleteTuto] = useState(false)
    const [searchBox, setSearchBox] = useState("")
    const [add, setAdd] = useState(false)




    const handleSearch = (e) => {
        const value = e.target.value
        setSearchBox(value)
        
        const searchString = value.trim().toLowerCase()

        const dbTransform = props.dataTutorials.map(element => {
            return {
                id : element.id, 
                query: element.title + element.titleEn, 
            }

        })

        const searchData = dbTransform.filter(element => element.query.toLowerCase().match(searchString)).map(element => element.id)


        props.setListTutorials(props.dataTutorials.filter(element => searchData.includes(element.id)))
    }

    const handleClick = (element) => {
        /*const transformElement = {
            title : {
                status : false, 
                content : element.title
            }, 
            titleEn : {
                status : false, 
                content : element.titleEn
            }, 
            embedId : {
                status : false, 
                content : element.embedId
            }, 
            subSections : element.subSections.length > 0 ? 
                element.subSections.map(subElement => {
                    return {
                        status : false, 
                        content : subElement
                    }
                })
            : []
        }
        props.setDetailTutorial(transformElement)*/
        props.setDetailTutorial(element)
    }

    const handleDeleteTuto =(id) => {
        props.setLoader(true)
        axios.post(`${hostname}/tutorials/delete`, { id : id})
        .then(res => {
            props.setDataTutorials(props.dataTutorials.filter(element => element.id !== id))
            props.setListTutorials(props.listTutorials.filter(element => element.id !== id))
            props.setLoader(false)
            props.setStatusRequest(res.data)
        })
        .catch(err => {
            props.setLoader(false)
            props.setStatusRequest({
                status : false, 
                content : "Une erreur est survenue, veuillez réessayer"
            })
        })
    }

    

    return (
        <React.Fragment>
        <AnimatePresence exitBeforeEnter initial = {false}>
            {
                add ? 
                    <Add
                        setAdd = {setAdd}
                        setListTutorials = {props.setListTutorials}
                        setDataTutorials = {props.setDataTutorials}
                        setLoader = {props.setLoader}
                        setStatusRequest = {props.setStatusRequest}
                    />
                : 

                    null
            }
        </AnimatePresence>

        <motion.div 
        exit = "exit"
        animate = "animate"
        initial = "initial"
        variants = {homepageContainer}
        transition = {{
            type : "linear"
        }}
        className = "homepage">
            <div className = "homepage__search">
                <div>
                    <input type = "text" value = {searchBox} onChange = {handleSearch} placeholder = "Recherche" />
                    <AnimatePresence exitBeforeEnter initial = {false}>
                    {
                        searchBox.length > 0 ? 
                        <motion.div 
                            exit = "exit"
                            animate = "animate"
                            initial = "initial"
                            variants = {deleteTutoVariants}
                            whileTap={{scale : 0.8}} onClick = {() => {
                            setSearchBox("")
                            props.setListTutorials(props.dataTutorials)
                        }}><CancelSVG /></motion.div>

                        : 
                            null
                    }
                    </AnimatePresence>

                </div>
            </div>

            <div className = "homepage__list">
                <div className = "homepage__list__container">
                    <AnimatePresence>
                    {
                    props.listTutorials.length > 0 ? 
                        props.listTutorials.map(element => (
                            <motion.div 
                                exit = "exit"
                                animate = "animate"
                                initial = "initial"
                                variants = {listElementHomepage}
                                transition = {{
                                    type : "linear"
                                }}
                                whileTap = {{scale : 0.9}}
                                key = {JSON.stringify(element)} 
                                onClick = {() => deleteTuto ? handleDeleteTuto(element.id) : handleClick(element)}
                            >
                                <div>
                                    <AnimatePresence exitBeforeEnter initial = {false}>
                                    {
                                        deleteTuto ? 
                                            <motion.div 
                                            exit = "exit"
                                            animate = "animate"
                                            initial = "initial"
                                            variants = {deleteTutoVariants}
                                            className='cancelTuto' 
                                            
                                            ><CancelSVG /></motion.div>
                                        : 
                                            null
                                    }
                                    </AnimatePresence>

                                    <p>{element.title}</p>
                                    <p>{element.titleEn}</p>
                                </div>
                                <div>
                                    <TriangleSVG/>
                                </div>
                            </motion.div>
                        ))
                    : 
                        <motion.div 
                            id = "noData"
                            exit = "exit"
                            animate = "animate"
                            initial = "initial"
                            variants = {noDataHomepage}
                        >
                            <NoDataSVG />
                            <p>Aucun tuto n'a été trouvé</p>
                        </motion.div>
                    }
                    </AnimatePresence>
                </div>
            </div>
            

        </motion.div>
        <motion.div whileTap = {{scale : 0.8}} className = "app__add" onClick = {() => setAdd(!add)}>
            <div></div>
            <AddSVG 

            />
        </motion.div>

        <motion.div whileTap = {{scale : 0.8}} className = "app__minus" onClick = {() => setDeleteTuto(!deleteTuto)}>
            <div></div>
            <MinusSVG

            />
        </motion.div>
        </React.Fragment>
    )
}