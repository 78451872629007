import React from 'react'
import { motion } from 'framer-motion'
import { loaderContainer, loaderContent  } from '../animations/loader'

export default function Loader() {
    return (
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            variants={loaderContainer}
            className="app__loader"
        >
            <motion.div
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants={loaderContent}
                className="app__loader"
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </motion.div>
        </motion.div>
    )
}