import React, {useState} from 'react'
import { motion } from 'framer-motion'
import {ReactComponent as CancelSVG} from '../../../svg/cancel__icon.svg'
import {ReactComponent as CheckSVG} from '../../../svg/check__icon.svg'
import { addContainer, addContent } from '../../animations/add'
import axios from 'axios'
import { hostname } from '../../../config/server'


export default function Add(props) {
    const [newTuto, setNewTuto] = useState({
        title : "", 
        titleEn : "", 
        embedId : "", 
        subSections : [], 
    })

    const handleChange = (e) => {
        if(e.target.name === "embedId") {
            setNewTuto(state => {
                return {
                    ...state, 
                    [e.target.name] : e.target.value.replace(/^https:\/{2}[a-z]*.{1}[a-z]*\//g, "")
                }
            })

        }
        setNewTuto(state => {
            return {
                ...state, 
                [e.target.name] : e.target.value
            }
        })
    }

    const handleSubmit = async () => {
        if(newTuto.title.length === 0) {
            return props.setStatusRequest({
                status : false, 
                content : "Le titre est incomplet"
            })
        }

        if(newTuto.titleEn.length === 0) {
            return props.setStatusRequest({
                status : false, 
                content : "Le titre anglais est incomplet"
            })
        }

        if(newTuto.embedId.length === 0) {
            return props.setStatusRequest({
                status : false, 
                content : "Le code Youtube est incomplet"
            })
        }




        if(newTuto.title.length > 0 && newTuto.titleEn.length > 0 && newTuto.embedId.length > 0) {
            props.setLoader(true)
            await axios.post(`${hostname}/tutorials/add`, newTuto)
            .then(async (res) => {
                const dataTuto = {
                    id : res.data.newTutorial.id, 
                    title : newTuto.title, 
                    titleEn : newTuto.titleEn, 
                    embedId : newTuto.embedId, 
                    subSections : [], 
                }
                props.setListTutorials(state => {
                    return [
                        dataTuto, 
                        ...state
                    ]
                })
                props.setDataTutorials(state => {
                    return [
                        dataTuto, 
                        ...state
                    ]
                })
                props.setLoader(false)
                props.setAdd(false)
                props.setStatusRequest({
                    status : true, 
                    content : "Le mémo est créé"
                })
            })
            .catch(err => {
                props.setLoader(false)
                props.setStatusRequest({
                    status : false, 
                    content : "Une erreur est survenue, veuillez réessayer"
                })
            })
            
            /**/
        }
        
    }



    return (
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            variants = {addContainer}
            className = "add">
                <motion.div
                    exit = "exit"
                    animate = "animate"
                    initial = "initial"
                    variants = {addContent}
                >
                    <div className = "add__header">
                        <div>
                            <motion.div whileTap = {{scale : 0.8}} onClick = {() => props.setAdd(false)}><CancelSVG /></motion.div>
                        </div>
                    </div>

                    <div className = "add__form">
                        <div>
                            <label htmlForm = "title">Titre du tutoriel</label>
                            <input type = "text" name = "title" value = {newTuto.title} onChange = {handleChange} placeholder = "Remplir/Teintes/Trichrome/CC Toner"/>
                        </div>

                        <div>
                            <label htmlForm = "titleEn">Titre du tutoriel en anglais</label>
                            <input type = "text" name = "titleEn" placeholder = "Remplir/Teintes/Trichrome/CC Toner" value = {newTuto.titleEn} onChange = {handleChange}/>
                        </div>

                        <div>
                            <label htmlForm = "embedId">Code du lien Youtube</label>
                            <input type = "text"  name = "embedId" placeholder = "Remplir/Teintes/Trichrome/CC Toner" value = {newTuto.embedId} onChange = {handleChange}/>
                        </div>
                    </div>
                    
                    <div className = "add__buttons">
                        <motion.div whileTap={{scale : 0.8}} onClick = {handleSubmit}>
                            <p>Valider</p>
                            <div><CheckSVG /></div>

                        </motion.div>

                    </div>

                </motion.div>
        </motion.div>
    )
}