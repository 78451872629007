import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import { loaderContainer, loaderContent } from '../animations/loader'
import {ReactComponent as CancelSVG} from '../../svg/cancel__icon.svg'
import {ReactComponent as CheckSVG} from '../../svg/check__icon.svg'

export default function StatusRequest({statusRequest, setStatusRequest}) {
    useEffect(() => {
        const timer = setTimeout(() => {
            setStatusRequest({})
        }, 3000);
        return () => clearTimeout(timer);
      });


    return (
            <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            variants={loaderContainer}
            className="statusRequest" 
            onClick = {() => setStatusRequest({})}>
            <motion.div
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants={loaderContent}
                style = {{background : statusRequest.status ? "green" : "rgb(177, 1, 1)"}}
            >
                <div className = "statusRequest__svg">
                    {
                        statusRequest.status ? 
                            <CheckSVG />
                        :
                            <CancelSVG />
                    }
                </div>

                <div className='statusRequest__content' >
                    {statusRequest.content}
                </div>
            </motion.div>
        </motion.div>
    )
}