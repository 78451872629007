import Main from './components/Main';
import logo from './logo.svg';
import './styles/style.css' ; 

function App() {
  return (
    <Main />
  );
}

export default App;
