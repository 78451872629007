
import { motion } from "framer-motion"
import { visualizerContentVariants, visualizerVariants } from "../../animations/visualizer"

export default function ImagePreview({imagePreview, setImagePreview}) {

    return(
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            variants = {visualizerVariants}
            className='image__preview' 
            onClick={() => setImagePreview("")}>
            <motion.img 
                src = {imagePreview} 
                alt = {"preview"} 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants = {visualizerContentVariants} 
            />
        </motion.div>
    )
}