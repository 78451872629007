

export const visualizerVariants = {
    exit : {
        opacity : 0, 
    }, 
    animate : {
        opacity : 1, 
    }, 
    initial : {
        opacity : 0, 
    }
}
export const visualizerContentVariants = {
    exit : {
        scale : 0
    }, 
    animate : {
        scale : 1, 
    }, 
    initial : {
        scale : 0
    }
}
