

export const detailContainer = {
    exit : {
        x : 1000, 
    }, 
    animate : {
        x : 0, 
    }, 
    initial : {
        x : 1000, 
    }
}

export const detailContent = {
    exit : {
        y : 1000, 
    }, 
    animate : {
        y : 0, 
        transition : {
            delay : 0.3, 
            type : "linear"
        }
    }, 
    initial : {
        y : 1000, 
    }
}

export const titleDetail = {
    offscreen : {
        y : 50, 
        opacity : 0, 

    }, 
    onscreen : {
        y : 0, 
        opacity : 1, 
        transition : {
            type : "linear", 
            duration : 0.5
        }
    }
}

export const detailSubSections = {
    offscreen : {
        y : 100, 
        opacity : 0
    }, 
    onscreen : {
        y : 0, 
        opacity : 1, 
        transition : {
            type : "linear", 
            duration : 0.5
        }
    }
}


export const kebabMenu = {
    exit : {
        height : 0, 
        overflow : "hidden",
        transition : {
            type : "linear", 
        }
    }, 
    animate : {
        height : "auto", 
        overflow : "hidden",
        transition : {
            type : "linear", 
        }
    }, 
    initial : {
        height : 0,
        overflow : "hidden", 
        transition : {
            type : "linear", 
        }
    }
}