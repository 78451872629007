import React from 'react'
import { motion } from 'framer-motion'
import { visualizerContentVariants, visualizerVariants } from '../../animations/visualizer'
import {ReactComponent as OpenWindowSVG} from '../../../svg/open_window_icon.svg'
import {ReactComponent as CancelSVG} from '../../../svg/cancel__icon.svg'


export default function Visualizer({visualizer, setVisualizer, handleEdit, detailTutorial}) {

    const handleChange = (e) => {
        handleEdit(e)
        setVisualizer(e.target.value)

        //.replace(/^https:\/{2}[a-z]*.{1}[a-z]*\//g, "")
    }

    return (
        <motion.div 
        exit = "exit"
        animate = "animate"
        initial = "initial"
        variants = {visualizerVariants}
        className = "visualizer" > 
            <div className='visualizer__close' onClick = {() => setVisualizer("")}>
                <CancelSVG />
            </div>
            <motion.div className='visualizer__embedCode'>
                <label htmlFor='embedId'>Embed Youtube Code</label>
                <input type = "text" name = "embedId" value = {detailTutorial.embedId} onChange = {handleChange} />
            </motion.div>                                      
              <motion.div
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants = {visualizerContentVariants} 
                transition = {{
                    type : "linear", 
                    delay : 0.5
                }}
              className="video-responsive">
                    <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${visualizer}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    />
                </motion.div>
                <motion.div 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants = {visualizerContentVariants} 
                transition = {{
                    type : "linear", 
                    delay : 0.8
                }}
                className = "visualizer__link">
                    <a href = {`https://www.youtube.com/embed/${visualizer}`} target="_blank" rel="noopener noreferrer">
                        Ouvrir dans un nouvel onglet
                    </a>
                    <motion.div 
                        whileTap = {{scale : 0.8}}
                    >
                        <OpenWindowSVG />
                    </motion.div>
                    

                </motion.div>
        </motion.div>
    )
}