import React, {useState, useRef} from 'react'
import { motion } from 'framer-motion'
import { addImageContainer, addImageContent } from '../../animations/addImage'
import {ReactComponent as CancelSVG} from '../../../svg/cancel__icon.svg'
import {ReactComponent as AddImageSVG} from '../../../svg/addImage__icon.svg'
import {ReactComponent as CheckSVG} from '../../../svg/check__icon.svg'
import {v4} from 'uuid'

export default function AddImage(props) {
    const ref = useRef(null)
    const [uploadedFile, setUploadedFile] = useState({})
    const [filePreview, setFilePreview] = useState('')

    const handleClickFile = (e) => {
        e.preventDefault()
        ref.current.click()
    }

    const handleSetFile = (e) => {
        setUploadedFile(e.target.files[0])
        setFilePreview(URL.createObjectURL(e.target.files[0]))
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        if(!uploadedFile.name) {
            return props.setStatusRequest({
                status : false, 
                content : "Aucun fichier sélectionné"
            })
        }
        if(uploadedFile.name) {
            props.setDetailTutorial(state => {
                return {
                    ...state, 
                    subSections : state.subSections.map(element => {
                        if(element.id === props.image.subSection) {
                            return {
                                ...element, 
                                image : [
                                    ...element.image, 
                                    [uploadedFile, v4(), false ]
                                ]
                            }
                        } else {
                            return element
                        }
                    })
                }
            })
            props.setImage({
                status : false, 
                subSection : null
            })
            
        }
    }





    return (
        <motion.div
            exit = "exit"
            animate = "animate"
            initial = "initial"
            variants = {addImageContainer}
            className = "addImage"
        >
            <motion.div
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants = {addImageContent}
            >
                <div className = "addImage__header">
                    <div>
                        <motion.div 
                            whileTap = {{scale : 0.8}}
                            onClick = {() => props.setImage({
                                status : false, 
                                subSection : null
                            })}
                        >
                            <CancelSVG />
                        </motion.div>
                    </div>
                </div>

                <form className='addImage__content'>
                    {filePreview !== '' ? 
                        <div className = "addImage__content--preview">
                            <img src = {filePreview} alt = "FilePreview" />
                        </div>
                    : 
                        null
                    }
                    <motion.div whileTap={{scale : 0.8}} onClick = {handleClickFile} className = "addImage__content--input">
                        <div><AddImageSVG /></div>
                        {
                            filePreview !== '' ? 
                            <p>Modifier l'image</p>
                            : 
                            <p>Importer une image</p>
                        }

                    </motion.div>

                    <input type = "file" accept="image/png, image/jpeg, image/jpg" style = {{display : 'none'}} name = "linkBrand" onChange = {props.handleChangeInput} ref = {ref} onChange = {handleSetFile}/>

                </form>

                <div className = "addImage__buttons">
                        <div onClick = {handleSubmit}>
                            <p>Valider</p>
                            <div><CheckSVG /></div>
                        </div>

                </div>


            </motion.div>

        </motion.div>
    )
}